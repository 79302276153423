import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Navbar.css';
import MiniLogo from './assets/MiniLogo.png'; // Import your logo image

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ul className="nav">
        {/* Left Section */}
        <div className="nav-left">
          <a href="/">
            <img src={MiniLogo} alt="App Logo" className="logo" />
          </a>
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">
              About
            </a>
          </li>
        </div>
      </ul>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ShortingLink</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Quick and Easy URL Shortener!</h2>
          <p>
            ShortURL is a tool for shortening long URLs from various platforms like{' '}
            <span className="highlight">Instagram</span>,{' '}
            <span className="highlight">Facebook</span>,{' '}
            <span className="highlight">YouTube</span>. Just paste a long link, click "Shorten URL,"
            and then copy and share the shortened link. You can also track the number of clicks on
            your shortened URLs, making it ideal for use in publications, ads, blogs, and messages.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navbar;
