import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './About.css';

const About = () => {
  return (
    <div className="page-container">
      <Navbar /> {/* Add the navigation bar */}
      <div className="content-wrap">
        <div className="about-container">
          <h1 className="about-title">About</h1>
          <p>
            Welcome to <strong>Pasting.org</strong>, a simple and efficient platform designed to make sharing and managing text content easier than ever.
          </p>
          <h2>What We Do</h2>
          <ul>
            <li>
              <strong>Create sharable text links:</strong> Enter your content, choose an expiration date, and generate a unique link to share your text securely.
            </li>
            <li>
              <strong>Secure text sharing:</strong> Decide how long your text is accessible by setting an expiration time, ensuring sensitive content doesn't linger online longer than intended.
            </li>
            <li>
              <strong>Retrieve shared content:</strong> Anyone with the unique link can view the shared text instantly, with no need for complex tools or accounts.
            </li>
          </ul>
          <h2>Why Use Pasting?</h2>
          <p>We aim to simplify how you manage and share text data with:</p>
          <ul>
            <li><strong>Privacy-first design:</strong> Your content is only accessible via the unique link you generate.</li>
            <li><strong>Expiration options:</strong> Automatically delete content after the specified duration to maintain privacy and declutter shared information.</li>
            <li><strong>Ease of use:</strong> No signup or login required—just generate and share links effortlessly.</li>
          </ul>
          <h2>Our Vision</h2>
          <p>
            At <strong>Pasting.org</strong>, we believe in simple, accessible tools that enhance productivity while respecting your privacy. Whether you’re sharing ideas or managing snippets of information, our platform ensures a seamless experience.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
