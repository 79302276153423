import React from 'react';
import './Footer.css'; // If you want to style the footer separately

const Footer = () => {
  return (
    <footer className="footer">
      <p> © 2024 <b> pasting.org</b></p>
    </footer>
  );
};

export default Footer;
