import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ExpirationForm from './ExpirationForm';
import NotFound from "./NotFound";
import ResultPage from './ResultPage';
import ResultPage2 from './ResultPage2.js';
import LoadingScreen from './LoadingScreen';
import About from './About'; // Import the About page

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ExpirationForm />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/about" element={<About />} /> {/* Add About route */}
        <Route path="/PasteDetails/redirect/:EncryptText" element={<ResultPage />} />
        <Route path="/pasting/redirect" element={<LoadingScreen/>} />
        {/* <Route path="/DisplayDetailPaste/:EncryptText" element={<ResultPage />} /> */}
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
      </Routes>
    </Router>
  );
}

export default App;
