import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ResultPage.css"; // Custom styles
import Navbar from "./Navbar"; // Navbar component
import Footer from "./Footer"; // Footer component

const ResultPage = () => {
  const { EncryptText } = useParams(); // Get shortUrl from the route parameter
  const [data, setData] = useState(null); // State to hold fetched data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [isExpired, setIsExpired] = useState(false); // State for expired links
  const [notFound, setNotFound] = useState(false); // State for not found links
  const [timeoutError, setTimeoutError] = useState(false); // State for timeout errors

  useEffect(() => {
    if (!EncryptText) return; // Prevent execution if EncryptText is undefined or null

    // Track if the component is still mounted
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      setTimeoutError(false); // Reset timeout error state

      const timeout = setTimeout(() => {
        if (isMounted) {
          setTimeoutError(true);
          setLoading(false);
        }
      }, 30000); // Set timeout for 30 seconds

      try {
        console.log("Fetching with EncryptText:", EncryptText); // Debugging log
        const response = await fetch(`https://localhost:7165/api/v1/DisplayDetailPaste/${EncryptText}`);

        clearTimeout(timeout); // Clear the timeout if the fetch completes

        if (!isMounted) return; // Exit early if component unmounted

        if (response.status === 400) {
          setIsExpired(true);
        } else if (response.status === 404) {
          setNotFound(true);
        } else if (response.ok) {
          const result = await response.json();
          console.log("Fetched data:", result); // Debugging log
          setData(result);
        } else {
          throw new Error("Unexpected error occurred");
        }
      } catch (error) {
        console.error("Fetch error:", error.message);
        toast.error("An error occurred. Please try again later.");
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [EncryptText]); // Dependency array ensures fetch runs only when EncryptText changes

  const handleDownload = () => {
    if (data?.data?.text) {
      const blob = new Blob([data.data.text], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${data.data.titleText || "text"}.txt`;
      link.click();
    } else {
      toast.error("No text available to download.", { autoClose: 2000 });
    }
  };

  const handleCopy = () => {
    if (data?.data?.text) {
      navigator.clipboard
        .writeText(data.data.text)
        .then(() => toast.success("Text copied to clipboard!", { autoClose: 2000 }))
        .catch(() => toast.error("Failed to copy text.", { autoClose: 2000 }));
    } else {
      toast.error("No text available to copy.", { autoClose: 2000 });
    }
  };

  if (timeoutError) {
    return (
      <div className="timeout-error-container">
        <Navbar />
        <div className="timeout-error">
          <h1>Server Unavailable</h1>
          <p>The server did not respond within 30 seconds. Please try again later.</p>
        </div>
        <Footer />
      </div>
    );
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  if (isExpired) {
    return (
      <div className="expired-message-container">
        <Navbar />
        <div className="expired-message">
          <h1>The link has expired</h1>
          <p>Please contact the owner of the link for more information.</p>
        </div>
        <Footer />
      </div>
    );
  }

  if (notFound) {
    return (
      <div className="not-found-message-container">
        <Navbar />
        <div className="not-found-message">
          <h1>Link not found</h1>
          <p>We couldn't find the requested link. Please check the URL and try again.</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="result-container">
      <Navbar />
      <div className="result-card">
        <div className="result-item">
          <span className="label">Expiration:</span>
          <span className="value">{data?.data?.expiration || "Not provided"}</span>
        </div>
        <hr />
        <div className="result-item">
          <span className="label">Description:</span>
          <span className="value">{data?.data?.description || "Not provided"}</span>
        </div>
        <hr />
        <div className="result-item">
          <h2 className="section-title">{data?.data?.titleText || "No Title"}</h2>
          <div className="section-content">
            <pre className="text-content">{data?.data?.text || "No text available"}</pre>
          </div>
        </div>
        <hr />
        <div className="result-actions">
          <button className="action-button download-button" onClick={handleDownload}>
            Download Text
          </button>
          <button className="action-button copy-button" onClick={handleCopy}>
            Copy Text
          </button>
        </div>
      </div>
      <ToastContainer position="top-center" />
      <Footer />
    </div>
  );
};

export default ResultPage;
