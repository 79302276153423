import React, { useEffect} from 'react';
import axios from 'axios';
import './LoadingScreen.css'; // Your styles
import myPhoto from './assets/Logo.png'; // Import the photo
import { useLocation } from 'react-router-dom'; // For capturing the query string
import {
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
  osName,
  osVersion,
  engineName,
  deviceType,
  isMobile,
  isTablet,
  isDesktop,
} from 'react-device-detect';


// Function to get GPU information
const getGPUInfo = () => {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  if (!gl) return 'Unknown GPU';

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  const gpuInfo = debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : 'Unknown GPU';
  return gpuInfo;
};

// Function to get connection type information
const getConnectionType = () => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  return connection ? connection.effectiveType || 'Unknown' : 'Unknown';
};

const LoadingScreen = () => {
  const url = "https://localhost:7165/api/v1"; // Your base API URL
  //const url = "https://pasting.org/api/v1"; // Your base API URL
  

  // Hook to capture the current URL and its search parameters
  const location = useLocation();
  // Utility to parse the query string and get parameters
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
        encryptText: searchParams.get('encryptText'),
    };
  };

  // Fetch IP and location
  const fetchIpAndLocation = async () => {
    try {
      const response = await axios.get(`${url}/IPInfo`);
      const data = response.data;
      return {
        ip: data.ip,
        city: data.city,
        region: data.region,
        country: data.country,
      };
    } catch (error) {
      console.error('Error fetching IP and location:', error);
      return {
        ip: 'Unknown',
        city: 'Unknown',
        region: 'Unknown',
        country: 'Unknown',
      };
    }
  };

  // Get system information from the browser
  const getSystemInfo = async () => {
    const ipLocation = await fetchIpAndLocation();
    console.log("ipLocation");
    const gpuInfo = getGPUInfo();
    console.log("gpuInfo");
    const connectionType = getConnectionType();
    console.log("connectionType");
    return {
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth,
      hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown', // the number of logical processors available
      platform: navigator.platform || 'Unknown',
      userAgent: navigator.userAgent,
      language: navigator.language,
      appVersion: navigator.appVersion,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ip: ipLocation.ip,
      city: ipLocation.city,
      region: ipLocation.region,
      country: ipLocation.country,
      browserName: browserName,            // Browser name (e.g., Chrome, Firefox)
      browserVersion: browserVersion,      // Browser version (e.g., 94.0)
      mobileVendor: mobileVendor || 'N/A', // Mobile vendor (e.g., Apple, Samsung)
      mobileModel: mobileModel || 'N/A',   // Mobile model (e.g., iPhone, Galaxy)
      osName: osName,                      // Operating system (e.g., Windows, macOS)
      osVersion: osVersion,                // OS version (e.g., 10, 11)
      engineName: engineName,              // Browser engine (e.g., Blink, Gecko)
      deviceType: deviceType,              // Device type (e.g., mobile, tablet, desktop)
      isMobile: isMobile,                  // Boolean for mobile devices
      isTablet: isTablet,                  // Boolean for tablets
      isDesktop: isDesktop,                // Boolean for desktop
      gpuInfo: gpuInfo,                    // GPU information
      connectionType: connectionType,      // Network connection type
    };
  };

  // Send system info to backend
  const sendSystemInfo = async (encryptText) => {
    const systemInfo = await getSystemInfo();
    console.log("systemInfo");
    console.log("systemInfo",systemInfo);
    try {
      const response = await axios.post(
        `${url}/FetchUsersData`, // API endpoint for the backend method
        {
          systemInfo,        // Sending system info
          EncryptText: encryptText,  // Send mainLink as MainLinkId
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      // Check if a redirectUrl is returned from the server
      const { redirectUrl } = response.data;
      console.log("redirectUrl",redirectUrl);
      if (redirectUrl) {
        // Perform the redirection in the frontend
        window.location.href = redirectUrl;
      }
  
      console.log('System info sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending system info:', error);
    }
  };
  
  // Effect to capture mainLink and send data
  useEffect(() => {
    const { encryptText } = getQueryParams();
    if (encryptText) {
      sendSystemInfo(encryptText);
    }
  }, []); // Empty array ensures this runs only once on page load

  return (
    <div className="loading-container">
      <img src={myPhoto} alt="My Photo" className="header-image-loading" /> {/* Replace logo with image */}
      <div className="dots">
        <span className="dot dot1"></span>
        <span className="dot dot2"></span>
        <span className="dot dot3"></span>
      </div>
    </div>
  );
};

export default LoadingScreen;