import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css"; // For styling

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1>404</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
        <Link to="/" className="btn-home">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
